import { Component } from "vue-property-decorator";
import Vue from "vue";

import { NftState } from "@/interfaces";

@Component
export default class NftMixin extends Vue {
  /*
   * NFT MODULE
   */

  get badgerPrice(): any {
    return this.$store.state.nft.badgerPrice;
  }

  set badgerPrice(payload: any) {
    this.$store.commit("nft/UPDATE_BADGER_PRICE", payload);
  }


  get yopPrice(): any {
    return this.$store.state.nft.yopPrice;
  }

  set yopPrice(payload: any) {
    this.$store.commit("nft/UPDATE_YOP_PRICE", payload);
  }
  
  get collection(): any {
    return this.$store.state.nft.collection;
  }

  set collection(payload: any) {
    this.$store.commit("nft/UPDATE_COLLECTION", payload);
  }

  get collections(): any {
    return this.$store.state.nft.collections;
  }
   
  get memeLtdContract(): any {
    return this.$store.state.nft.memeLtdContract;
  }

  set memeLtdContract(payload: any) {
    this.$store.commit("nft/UPDATE_MEME_LTD_CONTRACT", payload);
  }

  get memePrice(): any {
    return this.$store.state.nft.memePrice;
  }

  set memePrice(payload: any) {
    this.$store.commit("nft/UPDATE_MEME_PRICE", payload);
  }

  get myCollection(): any {
    return this.$store.state.nft.myCollection;
  }

  set myCollection(payload: any) {
    this.$store.commit("nft/UPDATE_MY_COLLECTION", payload);
  }

  get myWallet(): any {
    return this.$store.state.nft.myWallet;
  }

  set myWallet(payload: any) {
    this.$store.commit("nft/UPDATE_MY_WALLET", payload);
  }

  get totalNfts(): any {
    return this.$store.state.nft.totalNfts;
  }

  set totalNfts(payload: any) {
    this.$store.commit("nft/UPDATE_TOTAL_NFTS", payload);
  }

  get totalMarketCap(): any {
    return this.$store.state.nft.totalMarketCap;
  }

  set totalMarketCap(payload: any) {
    this.$store.commit("nft/UPDATE_TOTAL_MARKET_CAP", payload);
  }

  get pools(): any {
    return this.$store.state.nft.pools;
  }

  set pools(payload: any) {
    this.$store.commit("nft/UPDATE_POOLS", payload);
  }
}
