import { Genesis } from "../genesis/genesis";
import { Vans } from "../artist-drop/vans";
import { Sven } from "../artist-drop/sven";
import { BigComicArt } from "../artist-drop/big-comic-art";
import { DiegoRodriguez } from "../artist-drop/diego-rodriguez";
import { Jon } from "../artist-drop/jon";
import { Spongenuity } from "../artist-drop/spongenuity";
import { TheBlockTimes } from "../artist-drop/the-block-times";
import { Wolfe } from "../artist-drop/jonathan-wolfe";
import { Fewocious } from "../artist-drop/fewocious";
import { PrimeDao } from "../collections/prime-dao";
import { Labs } from "../event-drop/labs";
import { BadgerDao } from "../featured-drop/badger-dao";
import { LpBadgerDao } from "../featured-drop/lp-badger-dao";
import { Yop } from "../featured-drop/yop";
import { LpGenesis } from "../lp-genesis/lp-genesis";
import { BitcoinOrigins } from "../featured-drop/bitcoin-origins";
import { TerraVirtua } from "../featured-drop/terra-virtua";
import { Aymeric } from "../artist-drop/aymeric";
import { WizardSkull } from "../artist-drop/wizard-skull";
import { Cometh } from "../featured-drop/cometh";
import { Trippyogi } from "../artist-drop/trippyogi";
import { TyeDied } from "../artist-drop/tye-died";
import { Sinclair } from "../artist-drop/sinclair";
import { FrankWilder } from "../artist-drop/frank-wilder";
import { ExitSimulation } from "../artist-drop/exitsimulation";
import { Zeroxb1Collection } from "../artist-drop/0xb1-collection";
import { Remo } from "../artist-drop/remo";

// Use ES6 Object Literal Property Value Shorthand to maintain a map
// where the keys share the same names as the classes themselves
const classes: any = {
  Remo,
  Zeroxb1Collection,
  ExitSimulation,
  FrankWilder,
  Sinclair,
  TyeDied,
  Trippyogi,
  Cometh,		
  WizardSkull,	
  Aymeric,
  TerraVirtua,
  Genesis,
  Vans,
  Sven,
  BigComicArt,
  DiegoRodriguez,
  Jon,
  Labs,
  PrimeDao,
  Spongenuity,
  TheBlockTimes,
  BadgerDao,
  Yop,
  LpGenesis,
  LpBadgerDao,
  BitcoinOrigins,
  Wolfe,
  Fewocious
};

class DynamicClass {
  constructor(className: any, opts: any) {
    return new classes[className](opts);
  }
}

export default DynamicClass;
