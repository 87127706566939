import Vue from "vue";
import VueRouter from "vue-router";
// Components
import Dashboard from "@/views/Dashboard.vue";
import MyCollection from "@/views/MyCollection.vue";
import Overview from "@/views/Overview.vue";
import MemeFarm from "@/components/view_components/OverviewComponent.vue";
import Genesis from "@/components/meme-farms/genesis/index.vue";
import BadgerDao from "@/components/meme-farms/featured-drop/badger-dao.vue";
import BadgerDaoLp from "@/components/meme-farms/featured-drop/lp-badger-dao.vue";
import Yop from "@/components/meme-farms/featured-drop/yop.vue";
import TerraVirtua from "@/components/meme-farms/featured-drop/terra-virtua.vue";
import Cometh from "@/components/meme-farms/featured-drop/cometh.vue";
import ArtistDrop10 from "@/components/meme-farms/artist-drop/wizard-skull.vue";
import ArtistDrop11 from "@/components/meme-farms/artist-drop/trippyogi.vue";
import ArtistDrop12 from "@/components/meme-farms/artist-drop/tye-died.vue";
import ArtistDrop13 from "@/components/meme-farms/artist-drop/sinclair.vue";
import ArtistDrop14 from "@/components/meme-farms/artist-drop/frank-wilder.vue";
import ArtistDrop15 from "@/components/meme-farms/artist-drop/exitsimulation.vue";
import ArtistDrop16 from "@/components/meme-farms/artist-drop/0xb1-collection.vue";
import ArtistDrop17 from "@/components/meme-farms/artist-drop/remo.vue";
import ArtistDrop9 from "@/components/meme-farms/artist-drop/aymeric.vue";
import ArtistDrop8 from "@/components/meme-farms/artist-drop/fewocious.vue";
import ArtistDrop7 from "@/components/meme-farms/artist-drop/jonathanWolfe.vue";
import ArtistDrop6 from "@/components/meme-farms/artist-drop/theBlockTimes.vue";
import ArtistDrop5 from "@/components/meme-farms/artist-drop/spongenuity.vue";
import ArtistDrop4 from "@/components/meme-farms/artist-drop/bigComicArt.vue";
import ArtistDrop3 from "@/components/meme-farms/artist-drop/rodriguez.vue";
import ArtistDrop2 from "@/components/meme-farms/artist-drop/jon.vue";
import ArtistDrop1 from "@/components/meme-farms/artist-drop/vans.vue";
import ArtistDrop0 from "@/components/meme-farms/artist-drop/sven.vue";
import EventDrop0 from "@/components/meme-farms/event-drop/labs.vue";
import FeaturedDrop from "@/components/meme-farms/collections/prime-dao.vue";
import LPGenesis from "@/components/meme-farms/lp-genesis/index.vue";
import BitcoinOrigins from "@/components/meme-farms/featured-drop/bitcoin-origins.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      metaTags: [
        {
          name: "title",
          content:
            "NFT Prices, Bid/Ask Data and Market Capitalizations | NiftyRank",
        },
        {
          name: "description",
          content:
            "Top NFT prices with bid/ask data, list by market capitalization. Free access to current data for multiple NFT collections.",
        },
      ],
    },
  },
  {
    path: "/my-collection",
    name: "my-collection",
    component: MyCollection,
    meta: {
      metaTags: [
        {
          name: "title",
          content: "NFT Portfolio Tracker | NiftyRank",
        },
        {
          name: "description",
          content:
            "Connect your Ethereum wallet to view your NFT portfolio value. Sort and filter through your NFT collection and data.",
        },
      ],
    },
  },
  {
    path: "/meme-farms",
    name: "meme-farms",
    component: Overview,
    children: [
      {
        path: "/",
        name: "main",
        component: MemeFarm,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "MEME Farm Information and Analytics | NiftyRank",
            },
            {
              name: "description",
              content:
                "Review the information and analytics for all MEME farms ranked by Total Value Locked and explore the pineapple leaderboards.",
            },
          ],
        },
      },
      {
        path: "genesis",
        name: "Genesis",
        component: Genesis,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme Ltd Genesis Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme Ltd Genesis Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-1-vans",
        name: "Artist Drop 1 - Vans",
        component: ArtistDrop1,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Vans Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Vans Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-0-sven",
        name: "Artist Drop 0 - Sven",
        component: ArtistDrop0,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Sven Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Sven Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "event-drop-0-labs",
        name: "Event Drop 0 - LABS",
        component: EventDrop0,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x LA Blockchain Summit Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x LA Blockchain Summit Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-2-jon",
        name: "Artist Drop 2 - Jon",
        component: ArtistDrop2,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Jon Noorlander Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Jon Noorlander Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-3-diego-rodriguez",
        name: "Artist Drop 3 - Diego Rodriguez",
        component: ArtistDrop3,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Diego Rodriguez Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Diego Rodriguez Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-4-big-comic-art",
        name: "Artist Drop 4 - BigComicArt",
        component: ArtistDrop4,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x BigComicArt Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x BigComicArt Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-5-spongenuity",
        name: "Artist Drop 5 - Spongenuity",
        component: ArtistDrop5,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Spongenuity Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Spongenuity Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-6-the-block-times",
        name: "Artist Drop 6 - The Block Times",
        component: ArtistDrop6,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x TheBlockTimes Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x TheBlockTimes Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-7-jonathan-wolfe",
        name: "Artist Drop 7 - Jonathan Wolfe",
        component: ArtistDrop7,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Jonathan Wolfe Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Jonathan Wolfe Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-8-fewocious",
        name: "Artist Drop 8 - Fewocious",
        component: ArtistDrop8,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Fewocious Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Fewocious Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-9-aymeric",
        name: "Artist Drop 9 - Aymeric",
        component: ArtistDrop9,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Aymeric Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Aymeric Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },	  
      {
        path: "artist-drop-10-wizard-skull",
        name: "Artist Drop 10 - Wizard Skull",
        component: ArtistDrop10,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Wizard Skull Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Wizard Skull Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },	  
      {
        path: "artist-drop-11-trippyogi",
        name: "Artist Drop 11 - Trippyogi",
        component: ArtistDrop11,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Trippyogi Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Trippyogi Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },	  
      {
        path: "artist-drop-12-tye-died",
        name: "Artist Drop 12 - Tye Died",
        component: ArtistDrop12,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Tye Died Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Tye Died Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },	
      {
        path: "artist-drop-13-sinclair",
        name: "Artist Drop 13 - Sinclair",
        component: ArtistDrop13,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Sinclair Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Sinclair Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-14-frank-wilder",
        name: "Artist Drop 14 - Frank Wilder",
        component: ArtistDrop14,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Frank Wilder Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Frank Wilder Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "artist-drop-15-exitsimulation",
        name: "Artist Drop 15 - ExitSimulation",
        component: ArtistDrop15,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x ExitSimulation Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x ExitSimulation Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "0xb1-collection",
        name: "0xb1 Collection",
        component: ArtistDrop16,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x 0xb1 Artist Collection Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x 0xb1 Artist Collection Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "remo",
        name: "Remo",
        component: ArtistDrop17,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Remo Artist Series Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Remo Artist Series Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-terra-virtua",
        name: "Featured Drop - Terra Virtua",
        component: TerraVirtua,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Terra Virtua Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Terra Virtua Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-cometh",
        name: "Featured Drop - Cometh",
        component: Cometh,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Cometh Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Cometh Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },	  
      {
        path: "featured-drop-badger-dao",
        name: "Featured Drop - Badger DAO",
        component: BadgerDao,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Badger DAO Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Badger DAO Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-lp-badger-dao",
        name: "Featured Drop - LP Badger DAO",
        component: BadgerDaoLp,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Badger DAO (LP) Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Badger DAO (LP) Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-yop",
        name: "Featured Drop - YOP",
        component: Yop,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x YOP Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x YOP Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-prime-dao",
        name: "Featured Drop - Prime DAO",
        component: FeaturedDrop,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Prime DAO Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Prime DAO Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "featured-drop-bitcoin-origins",
        name: "Featured Drop - Bitcoin Origins",
        component: BitcoinOrigins,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme x Bitcoin Origins Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme x Bitcoin Origins Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
      {
        path: "lp-genesis",
        name: "LP Genesis",
        component: LPGenesis,
        meta: {
          metaTags: [
            {
              name: "title",
              content: "Meme Ltd LP Genesis Pineapple Leaderboard | NiftyRank",
            },
            {
              name: "description",
              content: "Search for your position within the Meme Ltd LP Genesis Pineapple Leaderboard, listed by total pineapples.",
            },
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Credits go to 
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el: any) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: any) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => {
      document.head.appendChild(tag);
      const referenceNode = document.getElementsByTagName("title")[0];
      document.head.insertBefore(tag, referenceNode);
    });

  next();
});

export default router;
